import React from 'react';

function Header() {
    return (
        <header>
            <h1>AUDITIVE</h1>
            <p>Dark, Minimal, Experimental, Techy Drum&Bass for Bass Addicts</p>
        </header>
    );
}

export default Header;
